.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  backdrop-filter: blur(2px); /* Add blur effect */
  -webkit-backdrop-filter: blur(5px); /* For Safari support */
  z-index: 999; /* Just below the modal */
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f6; /* Change to solid white */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: var(--color-black);
}

.pieChart {
  width: 300px;
  height: 300px;
  margin: 20px auto;
  border: 1px solid #bebebe;
  border-radius: 15px;
  padding: 30px;
  background-color: #ffffff;
}

.buttonGroup {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.buttonGroup button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin: 10px;
  background-color: var(--color-black);
  color: white;
  border: 1px solid #bebebe;
}

.buttonGroup button:first-child {
  background-color: black;
  color: white;
}

.buttonGroup button:last-child {
  background-color: var(--color-gray);
  color: black;
}

.buttonGroup button:hover {
  opacity: 0.7;
}
