.deckLoaderContainer {
  display: flex;
  width: 100%;
}

.deckContent {
  flex-grow: 1;
  padding-top: 40px;
  background: var(--color-warm-white);
}
