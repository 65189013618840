.createCard {
  margin: 10px;
  margin-top: 45px;
  border-radius: 20px;
  text-align: center;
  width: 40%;
  height: 300px;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  height: 550px;
  width: 450px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05), 0 0 30px 0 rgba(0, 0, 0, 0.05);
  transition: 0.2s ease;
}

.formDivider {
  border: 1px dashed var(--color-gray-lt);
  width: 100%;
  margin: 10px 0;
}

.createCard .createCardField {
  width: 400px;
  height: 250px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 6px;
  outline: none;
  border: none;
  text-align: left;
  font-size: 18px;
  color: var(--color-black);
}

.createCard input::placeholder {
  color: lightgray;
}

.addCardButtonSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform: translateY(60px);
}

.addCardTabIcon {
  margin-right: 5px;
  padding: 5px 10px;
  background: var(--color-black);
  border-radius: 6px;
  color: var(--color-white);
  font-size: 10px;
}

.addCardButton {
  border: none;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 500;
  background: none;
}
