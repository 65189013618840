body {
  font-family: Inter, sans-serif;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Inter, sans-serif;
}
