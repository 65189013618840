.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f3f3ee;
}

.loginContentContainer {
  display: flex;
  width: 400px;
  background: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.05);
}

.loginContainer p {
  margin-bottom: 30px;
  text-align: center;
}

.loginContainer button {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 200px;
  margin-bottom: 45px;
}
