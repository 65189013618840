/* DeckLearning */
.deckLearning {
  padding: 30px;
  background-color: var(--color-background);
  width: 100vw;
  min-height: 100vh;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.buttonOutline {
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.2s ease;
}

.buttonOutline:hover {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5); /* Keep the text color the same on hover */
}

.deckLearning .buttonOutline {
  position: absolute;
  left: 60px;
  top: 60px;
}

.deckLearning .card {
  background-color: var(--color-white);
  color: var(--color-black);
  width: 450px; /* Set the width of the card */
  height: 300px; /* Set the height of the card */
  position: relative;
  transition: transform 0.3s; /* Smooth transition for flipping */
  transform-style: preserve-3d; /* Preserve 3D for child elements */
  border-radius: 15px;
}

.deckLearning .card:hover {
  scale: none;
  cursor: inherit;
}

.deckLearning h2 {
  font-size: 65px;
  font-weight: 100;
  margin-bottom: 20px;
  opacity: 0.2;
}

.deckLearning .cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.deckLearning .cardContainer {
  perspective: 1000px; /* Add perspective for 3D effect */
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: space-between; /* Space between buttons and card */
}

.deckLearning .card.flipped {
  transform: rotateY(180deg); /* Rotate the card when flipped */
}

.deckLearning .cardFront,
.deckLearning .cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--color-black);
  background: var(--color-white);
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  top: 0;
  left: 0;
}

.deckLearning .cardBack {
  transform: rotateY(180deg); /* Rotate the back side */
  background: var(--color-light-gray); /* Different background for the back */
}

.cardStatusContainer {
  position: absolute;
  right: 15px;
  bottom: 0px;
}
.deckLearning .statusDot {
  width: 5px;
  height: 5px;
  margin: 2px;
  border-radius: 50%;
  display: inline-block;
}

.deckLearning .statusDot.correct {
  background-color: var(--color-correct);
}

.deckLearning .statusDot.incorrect {
  background-color: var(--color-incorrect);
}

.cardNavigationButton {
  background: rgba(0, 0, 0, 0.1);
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  color: var(--color-black);
  margin: 15px;
  color: rgba(0, 0, 0, 0.2);
  transition: 0.2s ease;
}

.cardNavigationButton:hover {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.cardNavigation {
  color: rgba(0, 0, 0, 0.3);
  margin-top: 15px;
}
.statusIndicator {
  display: flex;
  width: 450px;
  margin-top: 10px;
}

.statusBar {
  height: 3px;
  transition: background-color 0.3s ease;
  transition: height 0.3s ease;
  margin: 1px;
}

.statusBar.correct {
  background-color: var(--color-green);
}

.statusBar.incorrect {
  background-color: var(--color-red);
}

.statusBar.active {
  background-color: var(--color-black);
  height: 5px;
}

.statusBar.unguessed {
  background-color: gray;
}

.keyboardInstructions {
  bottom: 0;
  left: 0;
  width: 650px;
  margin-top: 30px;
}

.keyboardInstructionsImage {
  width: 100%;
}
