.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 9998;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  max-width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f6;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  color: var(--color-black);
}

.modal h2 {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 400;
}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal input[type="text"],
.modal textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #bebebe;
  border-radius: 5px;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
}

.buttonGroup button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  transition: background-color 0.3s;
}

.buttonGroup button:first-child {
  background-color: black;
  color: white;
  margin-bottom: 15px;
}

.buttonGroup button:last-child {
  background-color: var(--color-gray);
  color: rgb(150, 150, 150);
  border: none;
  padding: 5px;
  margin-bottom: none;
}

.buttonGroup button:hover {
  opacity: 0.7;
}

.createDeckLabels {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: space-between;
}

.createDeckLabels label {
  border: 1px solid var(--color-warm-gray-md);
  color: var(--color-warm-gray-md);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
