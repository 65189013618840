.loadingSpinner {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
}

.homeLink {
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 2px;
  margin-bottom: 15px;
  transition: 0.15s ease;
  color: var(--color-gray-md);
  position: fixed;
  left: 15px;
  top: 15px;
  z-index: 200;
}

.homeLink:hover {
  opacity: 0.7;
  cursor: pointer;
}

.homeLink a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #bdbdbd;
}

.homeLink a:hover {
  color: #bdbdbd; /* Keep the same color on hover */
}

.homeLink svg {
  margin-right: 5px;
}

/* CreateCard and Card__________________________________________________________________________________________ */
.mainDeckWindow {
  padding: 30px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 250px);
  margin-left: 250px;
  background: var(--color-warm-white);
}

.mainDeckWindow h2 {
  font-size: 45px;
  margin-bottom: 15px;
  font-weight: 100;
  color: var(--color-black);
}

.deckInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.deckInfo input {
  width: 75%;
  font-size: 45px;
  color: var(--color-black); /* Default color */
}

.deckInfo input:focus {
  color: var(--color-gray-md);
  /* Change color to gray when focused */
}

.deckInfo h2 {
  font-size: 45px;
  cursor: text;
  color: var(--color-black);
}

.deckLearnButton {
  background: black;
  color: var(--color-white);
  border: none;
  padding: 15px 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deckLearnButton svg {
  margin-right: 5px;
}

.deckLearnButton:hover {
  cursor: pointer;
  opacity: 0.7;
}

.deckContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.deckContainer h3 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 300;
  padding-bottom: 5px;
  color: var(--color-gray-md);
}

.assembledDeck {
  height: 100vh;
  overflow-y: auto;
  overflow-x: visible;
  padding: 0px 30px;
  z-index: 100;
}

.assembledDeck h3 {
  margin-bottom: 0px;
  margin-right: 15px;
}

.assembledDeckHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.deckCardCount {
  font-size: 10px;
  color: var(--color-gray-md);
  background: var(--color-inactive);
  padding: 4px 10px;
  border-radius: 15px;
  display: inline-block;
  margin-bottom: 10px;
}

/* I need to move this to a different file____ */
.cardRecommendations {
  z-index: 100;
  height: 100vh;
  overflow-y: auto;
  overflow-x: visible;
  padding: 0px 30px;
}

.recommendedCard {
  border: 1px solid var(--color-light-blue);
  background: #ebe5ff;
  background: blue;
  box-shadow: 0 0 5px 0 rgba(95, 54, 245, 0.2),
    0 0 15px 0 rgba(95, 54, 245, 0.1), 0 0 30px 0 rgba(95, 54, 245, 0.05);
}

.recommendedCard:hover {
  border: 1px solid var(--color-dark-blue);
  box-shadow: 0 0 5px 0 rgba(95, 54, 245, 0.75),
    0 0 15px 0 rgba(95, 54, 245, 0.1), 0 0 30px 0 rgba(95, 54, 245, 0.05);
}

.recommendedCard .question {
  color: var(--color-purple);
}

.recommendedCard .answer {
  color: var(--color-light-purple);
}

.hidden {
  display: none;
}

/* Mobile devices */
@media only screen and (max-width: 767px) {
  /* Styles for mobile devices */
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mainDeckWindow {
    padding: 30px 15px 0px 15px;

    width: calc(100% - 90px);
    margin-left: 0px;
  }

  .mainDeckWindow h2 {
    font-size: 35px;
    margin-bottom: 15px;
  }
}

/* Desktops */
@media only screen and (min-width: 1025px) {
  /* Styles for desktops */
}

/* Large screens */
@media only screen and (min-width: 1441px) {
  /* Styles for large screens */
}
