/* I need to consolidate all these into consistant global styles */
.deckLearnButton {
  background: black;
  color: var(--color-white);
  border: none;
  padding: 15px 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deckLearnButton svg {
  margin-right: 5px;
}

.deckLearnButton:hover {
  cursor: pointer;
  opacity: 0.7;
}

/* good approach */
.buttonPrimaryDk {
  background: black;
  color: var(--color-white);
  border: none;
  padding: 15px 15px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s ease;
}

.buttonPrimaryDk:hover {
  cursor: pointer;
  opacity: 0.7;
}
