.card {
  position: relative;
  margin-bottom: 10px;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
  width: 250px;
  border-radius: 10px;
  background: var(--color-white);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05), 0 0 30px 0 rgba(0, 0, 0, 0.05);
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  flex: 2;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  scale: 0.98;
}

.deleteIcon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.card:hover .deleteIcon {
  display: block;
}

.card .cardLabel {
  position: absolute;
  left: 8px;
  font-size: 10px;
  color: var(--color-gray-md);
  transform: translateX(-25px);
}

.card .question {
  color: var(--color-black);
  font-weight: 600;
}

.card .answer {
  color: var(--color-gray-md);
}

.deleteIcon {
  display: none; /* Hide by default */
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  cursor: pointer;
}

.deleteIcon svg {
  width: 15px;
  height: 15px;
}

.deleteIcon path {
  fill: rgba(0, 0, 0, 0.2);
}

.deleteIcon:hover path {
  fill: var(--color-red-md);
  transition: 0.3s ease;
}

.recommendedCard {
  composes: card;
  border: 1px solid var(--color-purple-dk);
  background: var(--color-purple-lt);
  box-shadow: 0 0 5px 0 rgba(95, 54, 245, 0.2),
    0 0 15px 0 rgba(95, 54, 245, 0.1), 0 0 30px 0 rgba(95, 54, 245, 0.05);
}

.recommendedCard:hover {
  border: 1px solid var(--color-dark-blue);
  box-shadow: 0 0 5px 0 rgba(95, 54, 245, 0.75),
    0 0 15px 0 rgba(95, 54, 245, 0.1), 0 0 30px 0 rgba(95, 54, 245, 0.05);
}

.recommendedCard .question {
  color: var(--color-purple-dk);
}

.recommendedCard .answer {
  color: var(--color-purple-md);
}

.recommendedCard:hover {
  border: 1px solid var(--color-purple-dk);
}

/* Mobile devices */
@media only screen and (max-width: 767px) {
  /* Styles for mobile devices */
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .card {
    margin-bottom: 5px;
    padding: 20px;
    width: 200px;
    font-size: 10px;
  }
}

/* Desktops */
@media only screen and (min-width: 1025px) {
  /* Styles for desktops */
}

/* Large screens */
@media only screen and (min-width: 1441px) {
  /* Styles for large screens */
}

/* xlscreens */
@media only screen and (min-width: 1841px) {
  .card {
    margin-bottom: 20px;
    padding: 30px;
    width: 350px;
    font-size: 14px;
  }
}
