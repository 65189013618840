/* DeckList__________________________________________________________________________________________ */
.deckList {
  background: var(--color-bg-main);
  height: 100vh;
  padding: 15px;
  width: 250px;
  border-right: 1px solid var(--color-border);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  padding-top: 60px;
}

.homeLink {
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 2px;
  margin-bottom: 15px;
  transition: 0.15s ease;
  color: var(--color-medium-gray);
  position: fixed;
  left: 15px;
  top: 15px;
  z-index: 200;
}

.homeLink:hover {
  opacity: 0.7;
  cursor: pointer;
}

.homeLink a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #bdbdbd;
}

.homeLink a:hover {
  color: #bdbdbd; /* Keep the same color on hover */
}

.homeLink svg {
  margin-right: 5px;
}

.deckListHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.deckListTitle {
  font-size: 18px;
  font-weight: 500;
}

.addDeckButton {
  width: 35px;
  height: 35px;
  border-radius: 45px;
  border: none;
  background: black;
  color: var(--color-white);
  transition: 0.2s ease;
  cursor: pointer;
}

.addDeckButton:hover {
  opacity: 0.7;
}

.searchInput {
  width: 100%;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
  outline: none;
  border: 1px solid var(--color-border);
  font-size: 14px;
  color: black;
  background: var(--color-inactive);
}

.searchInput::placeholder {
  color: var(--color-gray-md);
}

.deckList ul {
  font-size: 12px;
}

.deckItem {
  list-style: none;
  background: var(--color-inactive);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  transition: 0.15s ease;
  min-height: 65px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}

.deckItem:hover,
.deckItem.active {
  background: var(--color-hover);
}

.deckItem.active {
  background: var(--color-active);
}

.deleteIcon {
  display: none; /* Hide by default */
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  cursor: pointer;
}

.deleteIcon svg {
  width: 15px;
  height: 15px;
}

.deleteIcon path {
  fill: rgba(0, 0, 0, 0.2);
}

.deleteIcon:hover path {
  fill: var(--color-red);
  transition: 0.3s ease;
}

.deckItem:hover .deleteIcon {
  display: block;
}

.cardCount {
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
}

.cardCount img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.deckListAvatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  bottom: 15px;
  left: 15px;
}

/* Mobile devices */
@media only screen and (max-width: 767px) {
  .deckList {
    display: none;
  }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .deckList {
    display: none;
  }
}

/* Desktops */
@media only screen and (min-width: 1025px) {
  .deckList {
    width: 200px;
  }
}

/* Large screens */
@media only screen and (min-width: 1441px) {
  .deckList {
    width: 250px;

    height: 100vh;
  }
}
