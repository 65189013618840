/* Set box-sizing to border-box for all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reset margin and padding */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

/* Set a default background color */
body {
  background-color: var(--color-background);
}

/* Set default link styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Add a transition for smoother hover effects */
a {
  transition: color 0.3s ease;
}

/* Add a hover effect for links */
a:hover {
  color: var(--color-blue);
}

/* Set a default max-width for containers */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* CreateCard and Card__________________________________________________________________________________________ */
.main-deck-window {
  padding: 30px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 250px);
  margin-left: 250px;
}

.main-deck-window h2 {
  font-size: 45px;
  margin-bottom: 15px;
  font-weight: 100;
  color: var(--color-black);
}

.deck-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.deck-info input {
  width: 75%;
  font-size: 45px;
  color: var(--color-black); /* Default color */
}

.deck-info input:focus {
  color: var(--color-gray-md); /* Change color to gray when focused */
}

.deck-info h2 {
  font-size: 45px;
  cursor: text;
  color: var(--color-black); /* Ensure h2 is black by default */
}

.deck-learn-button {
  background: black;
  color: var(--color-white);
  border: none;
  padding: 15px 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deck-learn-button svg {
  margin-right: 5px;
}

.deck-learn-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.deck-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.deck-container h3 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 300;
  padding-bottom: 5px;
  color: var(--color-gray-md);
}

.assembled-deck {
  width: 25%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: visible;
  padding: 0px 30px;
}

.assembled-deck h3 {
  margin-bottom: 0px;
  margin-right: 15px;
}

.assembled-deck-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.deck-card-count {
  font-size: 10px;
  color: var(--color-gray-md);
  background: var(--color-inactive);
  padding: 4px 10px;
  border-radius: 15px;
  display: inline-block;
  margin-bottom: 10px;
}

.create-card {
  margin: 10px;
  margin-top: 45px;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  width: 40%;
  height: 300px;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  height: 550px;
  width: 450px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05), 0 0 30px 0 rgba(0, 0, 0, 0.05);
  transition: 0.2s ease;
}

.form-divider {
  border: 1px dashed var(--color-divider);
  width: 100%;
  margin: 10px 0;
}

.create-card .create-card-field {
  width: 400px;
  height: 250px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 6px;
  outline: none;
  border: none;
  text-align: left;
  font-size: 18px;
  color: var(--color-black);
}

.create-card input::placeholder {
  color: lightgray;
}

.add-card-button-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.add-card-tab-icon {
  margin-right: 5px;
  padding: 5px 10px;
  background: var(--color-black);
  border-radius: 6px;
  color: var(--color-white);
  font-size: 10px;
}

.add-card-button {
  border: none;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 500;
  background: none;
}

/* DeckLearning */
.deck-learning {
  padding: 30px;
  background-color: #1b1b1b;
  width: 100vw;
  min-height: 100vh;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.button-outline {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.2s ease;
}

.button-outline:hover {
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5); /* Keep the text color the same on hover */
}

.deck-learning .button-outline {
  position: absolute;
  left: 60px;
  top: 60px;
}

.deck-learning .card {
  background-color: var(--color-white);
  color: var(--color-black);
  width: 450px; /* Set the width of the card */
  height: 250px; /* Set the height of the card */
  position: relative;
  transition: transform 0.3s; /* Smooth transition for flipping */
  transform-style: preserve-3d; /* Preserve 3D for child elements */
}

.deck-learning .card:hover {
  scale: none;
  cursor: inherit;
}

.deck-learning h2 {
  font-size: 65px;
  font-weight: 100;
  margin-bottom: 20px;
  opacity: 0.2;
}

.deck-learning .cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.deck-learning .card-container {
  perspective: 1000px; /* Add perspective for 3D effect */
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: space-between; /* Space between buttons and card */
}

.deck-learning .card.flipped {
  transform: rotateY(180deg); /* Rotate the card when flipped */
}

.deck-learning .card-front,
.deck-learning .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--color-black);
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  top: 0;
  left: 0;
}

.deck-learning .card-back {
  transform: rotateY(180deg); /* Rotate the back side */
  background: var(--color-light-gray); /* Different background for the back */
}

.card-status-container {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  width: 100%;
}
.deck-learning .status-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.deck-learning .status-dot.correct {
  background-color: var(--color-correct); /* Green dot for correct guesses */
}

.deck-learning .status-dot.incorrect {
  background-color: var(--color-incorrect); /* Red dot for incorrect guesses */
}

.card-navigation-button {
  background: #242424;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  color: var(--color-black);
  margin: 15px;
  color: rgba(255, 255, 255, 0.2);
  transition: 0.2s ease;
}

.card-navigation-button:hover {
  background: #343434;
  cursor: pointer;
}
@import "./global/typography.css";
/* Import other global stylesheets here */
@import "./global/variables.css";
@import "./shared/layout.css";
@import "./shared/buttons.css";
/* ... */
