/* Color Variables */
:root {
  /* neutrals cool*/
  --color-black: #151417;
  --color-gray-dk: #5b5963;
  --color-gray-md: #a39fa9;
  --color-gray-lt: #f0f0f4;
  --color-white: #ffffff;

  /* neutrals warm */
  --color-warm-black: #282824;
  --color-warm-gray-dk: #91918a;
  --color-warm-gray-md: #d5d5ce;
  --color-warm-gray-lt: #f3f3ee;
  --color-warm-white: #f9f9f4;

  --color-bg-main: #f3f3ee;

  /* dynamic colors */
  --color-green-md: #0cde92;
  --color-red-md: #ff2323;

  --color-purple-dk: #674ff7;
  --color-purple-md: #ae9fea;
  --color-purple-lt: #eae6ff;

  --color-orange-dk: #fd6000;
  --color-orange-md: #e8b99d;
  --color-orange-lt: #ffefe5;

  /* refactor everything below here */
  --color-border: #e0e0e0;
  --color-hover: #d7d7ce;
  --color-active: #cfcfca;
  --color-active-hover: #c0c0b9;
  --color-inactive: #e7e7e2;
  --color-inactive-hover: #d7d7ce;
}
