.homePage {
  padding: 30px;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  padding-top: 130px;
  background: var(--color-bg-main);
}

.userInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 60px;
  width: 100%;
  position: fixed;
  top: 30px;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--color-inactive);
  margin-right: 10px;
}

.homePage input {
  width: 100%;
  margin-bottom: 10px;
  padding: 25px;
  border-radius: 60px;
  outline: none;
  border: 1px solid var(--color-border);
  font-size: 24px;
  background: white;
  padding-left: 30px;
}

.homePage .searchInput {
  color: var(--color-black);
}

.homePage input::placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-style: italic;
}

.userDecks {
  width: 80vw;
  max-width: 1200px;
}

.homePage ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px -15px;
}

.homePage li {
  list-style: none;
  border-bottom: 1px solid var(--color-border);
  padding: 30px 15px;
  width: 32%;
  height: 220px;
  background: var(--color-white);
  border-radius: 15px;
  margin-bottom: 15px;
  transition: 0.2s ease;
  cursor: pointer;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.04), 0 0 30px 0 rgba(0, 0, 0, 0.02),
    0 0 60px 0 rgba(0, 0, 0, 0.02);
  color: gray;
}

.homePage h3,
.homePage .cardCount,
.homePage li {
  transition: 0.3s ease;
}

.homePage a,
.homePage li a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

/* hover states__________________ */

.homePage li:hover {
  background: var(--color-warm-gray-lt);
}

.homePage li .cardIcon rect,
.homePage li .cardIcon path {
  stroke: gray;
}

.homePage li:hover h3 {
  transform: translateY(-10px);
}

.homePage li:hover .cardCount {
  transform: translateY(10px);
}

.homePage li:hover .cardCount path,
.homePage li:hover .cardCount rect {
  stroke: black;
}

.homePage li h3 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  color: black;
}

.logoutButton {
  color: rgba(0, 0, 0, 0.3);
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.logoutButton:hover {
  opacity: 0.8;
}

.deckLink {
  text-decoration: none;
  color: inherit;
}

.cardIcon {
  height: 15px;
  width: auto;
  margin-right: 5px;
}

.cardCount {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Delete icon */

.homepageDeck {
  position: relative;
}

.homepageDeck .deleteIcon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.deleteIcon svg {
  width: 15px;
  height: 15px;
}

.deleteIcon path {
  fill: rgba(0, 0, 0, 0.2);
}

.deleteIcon:hover path {
  fill: var(--color-red);
  transition: 0.3s ease;
}

.homepageDeck:hover .deleteIcon {
  display: block;
}

.createDeckButton {
  background: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.createDeckButton:hover {
  opacity: 0.8;
}

.homeDeckaddCTA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fcfcf9 !important;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 16px;
  color: var(--color-black);
  box-shadow: none !important ;
  position: relative;
}

.homeDeckaddCTA:hover {
  background: #ffefe5 !important;
  border: 1px solid #ff6000 !important;
  color: #ff6000 !important;
}

.homeDeckaddCTA p {
  transition: 0.1s ease;
  margin-top: 15px;
  color: gray;
}

.homeDeckaddCTA:hover p {
  color: #ff6000;
}

.deckCardIcon {
  width: 35px;
  height: 50px;
}

.deckCardSquare {
  position: absolute;
  width: 35px;
  height: 50px;
  background: var(--color-inactive);
  border-radius: 4px;
  transform-origin: bottom right;
  transition: 0.3s ease;
  border: 1px solid white;
}

.homeDeckaddCTA:hover .deckCardSquare:nth-of-type(1) {
  background: #d3d3d3;
  transform: rotate(0deg);
  border: 1px solid #ffefe5;
}

.homeDeckaddCTA:hover .deckCardSquare:nth-of-type(2) {
  background: #deaf92;
  transform: rotate(15deg);
  border: 1px solid #ffefe5;
}

.homeDeckaddCTA:hover .deckCardSquare:nth-of-type(3) {
  background: #fd6000;
  transform: rotate(25deg);
  border: 1px solid #ffefe5;
}

/* Mobile devices */
@media only screen and (max-width: 767px) {
  .homePage li {
    width: 100%;
  }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .homePage li {
    width: 49%;
  }
}

/* Desktops */
@media only screen and (min-width: 1025px) {
  /* Styles for desktops */
}

/* Large screens */
@media only screen and (min-width: 1441px) {
  /* Styles for large screens */
}
